export const MenuItems = [
    {
        title: 'Home',
        url: '#home',
        cName: 'nav-links'
        
    },
    {
        title: 'Company Vision & Overview',
        url: '#overview',
        cName: 'nav-links'
        
    },
    {
        title: 'IP Strategy',
        url: '#ipstrat',
        cName: 'nav-links'
        
    },
    {
        title: 'Financial Plan',
        url: '#financials',
        cName: 'nav-links'
        
    },
]